import { createWebHistory, createRouter } from 'vue-router'
import store from '@/store'


import admin from '@/routes/admin';
import front from './routes/front';
import auth from './routes/auth';

const routes = [
        ...auth,
        ...front,
        ...admin,
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach(async(to, from, next) =>{
    document.title = to.meta.title
    //await store.dispatch();
    if (to.meta.middleware == "guest") {
        if (store.state.auth.authenticated) {
            next({ name: "dashboard" })
        }
        next()
    } else {
        if(to.meta.middleware=="auth"){
            if (!store.state.auth.authenticated) {
                next({ name: "home" })
            }
            next()
        }
        //console.log(store.state.auth.authenticated)
        /* if (store.state.auth.authenticated) {
            next()
        } else {
            next()
        } */
        next()
    }
})

export default router
