<template>
  <div id="app">
    <div>
    <!-- ======= Header ======= -->
    <div id="header"  class="d-flex align-items-center">
      <div class="container d-flex justify-content-between align-items-center">
        <div class="logo">
          <h5><router-link to="/"><img src="/logo.jpeg" alt=""> <span>INVESTORS CLUB FOR AFRICA</span></router-link></h5>
          <!-- Uncomment below if you prefer to use an image logo -->
          <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
        </div>

        <nav v-if="!authenticated" id="navbar" class="navbar">
          <FrontNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id==11" id="navbar" class="navbar">
          <RevendeurNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        <nav v-if="user.role_id==2" id="navbar" class="navbar">
          <ConsultantNavbar />
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
        
      </div>
    </div><!-- End Header -->
    </div>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
  import Footer from './components/Layout/Footer/Footer.vue'
  import RevendeurNavbar from './components/Navbar/Revendeur.vue'
  import ConsultantNavbar from './components/Navbar/Consultant.vue'

  import FrontNavbar from './components/Navbar/Front.vue'
  import axios from "axios";
  import {mapActions} from 'vuex'
  import path from './services/path'
export default {
    name:'AppLayout',
  components: {
      Footer,
      RevendeurNavbar,
      FrontNavbar,
      ConsultantNavbar,
  },
    data: function(){
      return {
        authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
        user:this.$store.state.auth.user!=null?this.$store.state.auth.user:{},
        entreprise:this.$store.state.auth.entreprise,
        title: process.env.VUE_APP_TITLE
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.user;
      },
    },
    methods: {
      ...mapActions({
                signOut:"auth/signOut"
  }),
            async logout(){
                await axios.post(path.getApi()+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                    //this.$router.push({name:"home"})
                })
            }
    },
    mounted(){
     // console.log(this.currentUser);
}
  };
</script>
<style scoped>
    #content{
        min-height: 200px;
    }

    .navbar a{
        text-decoration: none;
    }

    #header .logo h1 a, #header .logo h1 a:hover{
      color: #fff;
      text-decoration: none;
    }

    #header .logo img{
      border-radius: 50%;
      margin-top: 8px;
    }

    #header .logo span{
      display: table-cell;
      vertical-align: middle;
      font-size: 1rem;
      font-family: "changa";
    }


    #header{
      background: #620b0e;
      height: 45px;
      
    }
    #header a{
      color: #fff;
    }

    .breadcrumbs a{
      text-decoration:none;
      color: #620b0e;
    }
</style>
<style>

@font-face {
  font-family: "changa";
  src: local("changa"),   url(./assets/fonts/Changa-VariableFont_wght.ttf) format("truetype");
}

body{
  font-family: changa;
}

#topbar, #footer{
  background: #ca2e05;
}

#topbar .contact-info i {
  font-style: normal;
  color: #fff;
}

.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: changa;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.3s;
}

#footer .footer-top{
  background: #620b0e;
}
</style>
