<template>
    <ul>
        <li><router-link to="/">Accueil</router-link></li>
        <li><router-link to="/aboutus">Qui Sommes-nous ?</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li><router-link to="/login">Se connecter</router-link></li>
    </ul>
</template>
<script>
    export default{
        name:'FrontNavbar', 
    }
</script>

<style scoped>
    .navbar{
        font-weight: 800;
    }
</style>